import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './Onboarding.css'; // Import the CSS file

const Onboarding = () => {
    const token = useSelector((state) => state.auth.token);

    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();

    if (token) {
        return <Navigate to="/home" />;
    }

    const onboardingData = [
        {
            image: '/assets/img/carM3.png',
            title: 'Welcome to EasyRides',
            description: 'Find the best cars for rent in your area quickly and easily.',
        },
        {
            image: '/assets/img/pickup.png',
            title: 'Select Destination',
            description: 'Select Your Destination, EasyRides Takes You There!',
        },
        {
            image: '/assets/img/route.png',
            title: 'Easy Booking',
            description: 'Book your car in just a few clicks.',
        },
        {
            image: '/assets/img/trip.png',
            title: 'Enjoy the Ride',
            description: 'Enjoy the Ride, Every Time with Easyrides.',
        },
    ];

    const handleNext = () => {
        if (currentPage < onboardingData.length - 1) {
            setCurrentPage(currentPage + 1);
        } else {
            navigate('/home'); // Redirect after the last onboarding page
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="onboarding-container">
            {/* Back Arrow Button */}
            {currentPage > 0 && (
                <button onClick={handlePrevious} className="back-arrow">
                    &#8592; {/* Unicode left arrow */}
                </button>
            )}

            <img src={onboardingData[currentPage].image} alt="Onboarding" className="onboarding-image" />
            <h2 className="onboarding-title">{onboardingData[currentPage].title}</h2>
            <p className="onboarding-description">{onboardingData[currentPage].description}</p>
            <button onClick={handleNext} className="onboarding-button">
                {currentPage === onboardingData.length - 1 ? 'Get Started' : 'Next'}
            </button>
        </div>
    );
};

export default Onboarding;
