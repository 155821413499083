import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './BottomTabBar.css'; // You can style the tab bar here

const BottomTabBar = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    const handleNavigation = (path) => {
        setLoading(true); // Show loader
        setTimeout(() => {
            setLoading(false); // Hide loader after navigation
            navigate(path); // Navigate to the desired route
        }, 1000); // Simulating a 1-second delay
    };

    return (
        <>
            {loading && (
                <div className="loader">
                    <div className="spinner"></div> {/* Add loader spinner here */}
                </div>
            )}
            <div className="osahan-page-footer mt-auto py-3 px-2 bg-white shadow">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="text-center lh-1 col" onClick={() => handleNavigation('/home')}>
                        <i className="fas fa-home icon text-primary"></i>
                        <span className="tab-link text-primary text-decoration-none">Home</span>
                    </div>
                    <div className="text-center lh-1 col" onClick={() => handleNavigation('/OfferList')}>
                        <i className="fas fa-tags icon text-primary"></i>
                        <span className="tab-link text-primary text-decoration-none">Offers</span>
                    </div>
                    <div className="text-center lh-1 col" onClick={() => handleNavigation('/PoolRide')}>
                        <i className="fas fa-car icon text-primary"></i>
                        <span className="tab-link text-primary text-decoration-none">Pool Ride</span>
                    </div>
                    <div className="text-center lh-1 col" onClick={() => handleNavigation('/profile')}>
                        <i className="fas fa-user icon text-primary"></i>
                        <span className="tab-link text-primary text-decoration-none">Profile</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BottomTabBar;
