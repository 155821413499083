import React from 'react';
import Slider from "react-slick";
import './OfferList.css'; // External CSS for styling

// Individual offer card component
const OfferCard = ({ offer }) => {
  return (
    <div className="offer-card">
      <img src={offer.imgSrc} alt={offer.title} className="offer-image" />
      <div className="offer-details">
        <h3>{offer.title}</h3>
        <p>{offer.description}</p>
        <span>{offer.subtext}</span>
        <a href={offer.link} className="offer-link">
          {offer.cta}
        </a>
      </div>
    </div>
  );
};

// Static "My Coupons" section
const MyCoupons = () => {
  const coupons = [
    {
      imgSrc: '/assets/img/PROMO3.png', // Placeholder image
      title: 'First Booking with EasyRides',
      description: 'Get ₹100 Off*',
      subtext: 'Minimum fare ₹3000',
      link: '/easyrides-first-booking',
      cta: '>',
    },
    {
      imgSrc: '/assets/img/PROMO4.png', // Placeholder image
      title: 'First Booking with EasyRides',
      description: 'Get ₹200 Off*',
      subtext: 'Minimum fare ₹6000',
      link: '/easyrides-first-booking',
      cta: '>',
    },
  ];

  return (
    <div>
      <h2 style={{color:'#6739b7', textAlign:'center', marginBottom:'20px'}}>My Coupons</h2>
      <div className="coupon-list">
        {coupons.map((offer, index) => (
          <OfferCard key={index} offer={offer} />
        ))}
      </div>
    </div>
  );
};

// "Other Offers" slider section
const OtherOffersSlider = () => {
  const otherOffers = [
    {
      imgSrc: '/assets/img/trip2.png',
      title: 'Pool Ride Discount',
      description: 'Get 15% Off on Pool Rides',
      subtext: 'Valid for today only',
      link: '/pool-rides',
      cta: '>',
    },
    {
      imgSrc: '/assets/img/trip2.png',
      title: 'Two-Way Trip Offer',
      description: 'Save ₹200 on Round Trips',
      subtext: 'Minimum booking ₹5000',
      link: '/two-way-trips',
      cta: '>',
    },
  ];

  // Slider settings for auto image change
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1, // Show only 1 offer at a time
    slidesToScroll: 1, // Scroll one at a time
    autoplay: true, // Auto-slide enabled
    autoplaySpeed: 4000, // Slide every 4 seconds
    arrows: false, // Hide next/prev arrows
  };

  return (
    <div className="other-offers-slider">
      <h2>Other Offers</h2>
      <Slider {...sliderSettings}>
        {otherOffers.map((offer, index) => (
          <OfferCard key={index} offer={offer} />
        ))}
      </Slider>
    </div>
  );                                            
};

// Main OfferList component
const OfferList = () => {
  // Function to handle back navigation
  const handleBackClick = () => {
    window.history.back(); // Navigate back to the previous page or handle back action
  };

  return (
    <div className="offer-container">
      {/* Back Arrow Button */}
      <button className="back-button" onClick={handleBackClick}>
        &#8592; {/* This is the left arrow character */}
      </button>

      {/* Static My Coupons section */}
      <MyCoupons />
      
      {/* Slider section for Other Offers */}
      {/* <OtherOffersSlider /> */}
    </div>
  );
};

export default OfferList;
