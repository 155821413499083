import React from 'react';
import { Link } from 'react-router-dom';
import './ContactUs.css'; // Make sure to create a CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ContactUs = () => {
  return (
    <div className="contact-page">
      {/* Back Arrow */}
      <div className="back-arrowContact">
        <Link to="/">
          <FontAwesomeIcon icon={faArrowLeft} />
        </Link>
      </div>
      
      {/* Contact Header */}
      <div className="contact-header">
        <h2>Contact Us</h2>
      </div>
      {/* Customer Care Info */}
      <div className="customer-care-info">
        <h3>Need Immediate Assistance?</h3>
        <p>Our customer care team is here to help you 24/7.</p>
        <div className="contact-details">
          <p><strong>Customer Care Number:</strong> +91-8210345655/9163091507</p>
          <p><strong>Official Email ID:</strong> cab@easyride.com</p>
        </div>
      </div>
      {/* Contact Form */}
      <div className="contact-form-container">
        <form className="contact-form">
          <div className="form-group">
            <label htmlFor="email">Your Email Address</label>
            <input type="email" id="email" placeholder="you@example.com" required />
          </div>
          <div className="form-group">
            <label>Select a topic:</label>
            <div className="button-group">
              <button type="button">Booking Related</button>
              <button type="button">Driver Behaviour </button>
              <button type="button">On Timing</button>
              <button type="button">Payment Issue</button>
              <button type="button">App Related</button>
              <button type="button">Suggestion</button>
              <button type="button">Signing in</button>
              <button type="button">Other</button>
            </div>
          </div>
          <div className="form-group">
            <label>Or tell us what you need help with:</label>
            <input type="text" placeholder="Enter a topic, like 'notifications'" />
          </div>
          <button type="submit" className="submit-btn">Get Help</button>
        </form>
      </div>

      
    </div>
  );
};

export default ContactUs;
