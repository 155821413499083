import React, { useEffect, useState } from 'react';
import { Map as MapLibreMap, NavigationControl } from "maplibre-gl";
import 'maplibre-gl/dist/maplibre-gl.css';
import polyline from '@mapbox/polyline';
import * as maplibregl from 'maplibre-gl';

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoute, faClock } from '@fortawesome/free-solid-svg-icons'; // Icon for distance and duration

const MapComponent = ({ paraRoute, pickupCoordinates, destinationCoordinates, fareDetails }) => {
    const [mapReady, setMapReady] = useState(false);
    const [showDetails, setShowDetails] = useState(true); // State to toggle visibility

    useEffect(() => {
        if (!mapReady) return;

        // Initialize the map
        const map = new MapLibreMap({
            container: "central-map",
            center: [86.36377, 23.33608],
            zoom: 15,
            style: "https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/style.json",
            transformRequest: (url, resourceType) => {
                if (url.includes("?")) {
                    url = url + "&api_key=tPfi8OvohL3Xyz3v8Mz6JFeSh8HeXkuGbWR3Yd4k";
                } else {
                    url = url + "?api_key=tPfi8OvohL3Xyz3v8Mz6JFeSh8HeXkuGbWR3Yd4k";
                }
                return { url, resourceType };
            },
        });

        // Add navigation control
        const nav = new NavigationControl({
            visualizePitch: true,
        });
        map.addControl(nav, "bottom-left");

        // Decode the polyline
        const encodedPolyline = paraRoute;
        const decodedPolyline = polyline.decode(encodedPolyline);
        const coordinates = decodedPolyline.map(([lat, lon]) => [lon, lat]);

        // Convert to GeoJSON
        const routeGeoJSON = {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: coordinates,
            },
        };

        // Add the route to the map
        map.on('load', () => {
            map.addSource('route', {
                type: 'geojson',
                data: routeGeoJSON,
            });

            map.addLayer({
                id: 'route-layer',
                type: 'line',
                source: 'route',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#FF0000',
                    'line-width': 4
                }
            });

            // Calculate the bounds of the route
            const bounds = coordinates.reduce((bounds, coord) => {
                return bounds.extend(coord);
            }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));

            // Fit the map to the bounds of the route
            map.fitBounds(bounds, {
                padding: 20
            });

            // Add pickup marker (green)
            const pickupMarker = new maplibregl.Marker({ color: 'green' })
                .setLngLat(pickupCoordinates)
                .addTo(map);

            // Add hover effects and animations to the pickup marker
            pickupMarker.getElement().addEventListener('mouseenter', () => {
                pickupMarker.getElement().style.transform = 'scale(1.2)';
                pickupMarker.getElement().style.transition = 'transform 0.3s';
            });

            pickupMarker.getElement().addEventListener('mouseleave', () => {
                pickupMarker.getElement().style.transform = 'scale(1)';
                pickupMarker.getElement().style.transition = 'transform 0.3s';
            });

            // Add destination marker (red)
            const destinationMarker = new maplibregl.Marker({ color: 'red' })
                .setLngLat(destinationCoordinates)
                .addTo(map);

            // Add hover effects and animations to the destination marker
            destinationMarker.getElement().addEventListener('mouseenter', () => {
                destinationMarker.getElement().style.transform = 'scale(1.2)';
                destinationMarker.getElement().style.transition = 'transform 0.3s';
            });

            destinationMarker.getElement().addEventListener('mouseleave', () => {
                destinationMarker.getElement().style.transform = 'scale(1)';
                destinationMarker.getElement().style.transition = 'transform 0.3s';
            });

            // Add moving marker (blue)
            const movingMarker = new maplibregl.Marker({ color: 'blue' })
                .setLngLat(coordinates[0])
                .addTo(map);

            // Add hover effects and animations to the moving marker
            movingMarker.getElement().addEventListener('mouseenter', () => {
                movingMarker.getElement().style.transform = 'scale(1.2)';
                movingMarker.getElement().style.transition = 'transform 0.3s';
            });

            movingMarker.getElement().addEventListener('mouseleave', () => {
                movingMarker.getElement().style.transform = 'scale(1)';
                movingMarker.getElement().style.transition = 'transform 0.3s';
            });

            // Animation variables
            let step = 0;
            const steps = 500; // Total steps for the animation
            const animationSpeed = 0.8; // Adjust this value to control the speed of the marker

            function animateMarker() {
                step += animationSpeed;
                if (step >= coordinates.length - 1) {
                    step = 0; // Reset the animation once it reaches the end
                }

                const nextStep = Math.min(Math.floor(step), coordinates.length - 1);
                movingMarker.setLngLat(coordinates[nextStep]);

                requestAnimationFrame(animateMarker);
            }

            animateMarker(); // Start the animation
        });

    }, [mapReady, paraRoute, pickupCoordinates, destinationCoordinates]);

    // Toggle function to show or hide the fare details
    const toggleDetails = () => {
        setShowDetails(prevState => !prevState);
    };

    return (
        <div style={{ position: 'relative', height: '35vh', overflow: 'hidden' }}>
            {/* Map Container */}
            <div
                ref={() => setMapReady(true)}
                id="central-map"
                style={{ height: '100%', width: '100%' }}
            />

            {/* Fare Details in Top Right Corner */}
            <div 
                onClick={toggleDetails} // Click event to toggle visibility
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '10px',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    cursor: 'pointer',
                    zIndex: 1000,
                }}
            >
                <h6 style={{textAlign:'center'}}>Details</h6>
                {showDetails && ( // Conditionally render distance and duration
                    <>
                        <p>
                            <FontAwesomeIcon icon={faRoute} style={{ marginRight: '8px', color: '#007bff' }} />
                             {fareDetails?.distance} km
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faClock} style={{ marginRight: '8px', color: '#28a745' }} />
                             {fareDetails?.duration} hr
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};

export default MapComponent;
