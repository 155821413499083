import React , { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated hook for react-router-dom v6
import { Link } from 'react-router-dom';
import './Policy.css'; // Ensure this CSS file is linked correctly
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

const PolicyComponent = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // State for loading

    const handleButtonClick = () => {
        setLoading(true); // Show loader on click
        // Simulate a delay to demonstrate the loader
        setTimeout(() => {
            navigate('/');
            setLoading(false); // Hide loader after navigation
        }, 1000);
    };

    return (
        <div className="policy-container">
                  {loading && (
                <div className="loader">
                    <div className="spinner"></div>
                </div>
                   )}
            <h2 className="title-policy">Policy</h2>
            <div className="policy-content">
                <div className="back-arrowPolicy">
                    <Link to="/">
                        <FontAwesomeIcon icon={faArrowLeftLong} />
                    </Link>
                </div><br />
                <h2 className="policy-title">Rule and Regulation</h2>
                <ul className="policy-list">
                    <li><span className="dot red"></span> Schedule your ride 8 hours before for Pickup from Purulia, for other pickup locations please schedule 12 hours before.</li>
                    <li><span className="dot red"></span> Excludes toll tax, parking, state fee, etc.</li>
                    <li><span className="dot red"></span> Approx 20% Advance payment compulsory for ride confirmation.</li>
                    <li><span className="dot red"></span> 15₹/km will be charged for extra kilometer.</li>
                    <li><span className="dot red"></span> Advance paid will be non-refundable if cancelled by customer.</li>
                </ul>

                <h2 className="policy-title">Ride Inclusions</h2>
                <ul className="policy-list">
                    <li><span className="dot green"></span> 24x7 Dedicated ride support.</li>
                    <li><span className="dot green"></span> Verified drivers.</li>
                    <li><span className="dot green"></span> Clean & well-maintained cabs.</li>
                    <li><span className="dot green"></span> 100% On-Time pickup guaranteed.</li>
                </ul>

                <h2 className="policy-title">Vehicle Rates</h2>
                <div className="table-container">
                    <table className="vehicle-table">
                        <thead>
                            <tr>
                                <th>Type of Vehicle</th>
                                <th>Rate per km (Non-Ac)</th>
                                <th>Rate per km (Ac)</th>
                                <th>Timing Available</th>
                                <th>Holding Charge (Per/Night)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Mini</td>
                                <td>₹11/km</td>
                                <td>₹12/km</td>
                                <td>6:00 AM - 10:00 PM</td>
                                <td>₹500</td>
                            </tr>
                            <tr>
                                <td>SUV</td>
                                <td>₹12/km</td>
                                <td>₹13/km</td>
                                <td>6:00 AM - 10:00 PM</td>
                                <td>₹700</td>
                            </tr>
                            <tr>
                                <td>Sedan</td>
                                <td>₹15/km</td>
                                <td>₹16/km</td>
                                <td>6:00 AM - 10:00 PM</td>
                                <td>₹1500</td>
                            </tr>
                            <tr>
                                <td>Luxury</td>
                                <td>₹20/km</td>
                                <td>₹25/km</td>
                                <td>6:00 AM - 10:00 PM</td>
                                <td>₹1800</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* Add star mark and details here */}
                <p className="vehicle-details">
                    * Please note: 
                    <ul>
                        <li><b>Mini:</b> Compact vehicles ideal for city rides with basic comfort. <span className="example-text">Example: Maruti S Presso</span></li>
                        <li><b>SUV:</b> Spacious and suitable for family trips with extra luggage capacity. <span className="example-text">Example: Swift Dzire, Honda Amaze</span></li>
                        <li><b>Sedan:</b> Executive class vehicles with superior comfort and style. <span className="example-text">Example: Ertiga, Scorpio</span></li>
                        <li><b>Luxury:</b> Premium vehicles offering top-tier comfort and luxury experience.</li>
                    </ul>
                </p>

                <button className="navigate-button" onClick={handleButtonClick}>BOOK RIDE</button>
            </div>
        </div>
    );
};

export default PolicyComponent;
