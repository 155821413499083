import React, { useState } from 'react';
import './VehicleList.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome

const VehicleList = ({ vehicles, openModal, fareDetails, setFareDetails }) => {
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupContent, setPopupContent] = useState({ driverName: '', location: '' });
    const [selectedType, setSelectedType] = useState('All'); // State for selected vehicle type

    const handleBookNow = (vehicle) => {
        const fare = vehicle.calculated_fare;
        setFareDetails({ totalFare: fare });
        openModal(vehicle, fare);
    };

    const handleDriverClick = (driverName, location) => {
        setPopupContent({ driverName, location });
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
    };

    // Filter the vehicles based on the selected type
    const filteredVehicles = selectedType === 'All'
        ? vehicles
        : vehicles.filter(vehicle => vehicle.vehicle_type === selectedType);

    return (
        <div className="vehicle-list-container">
            {/* Conditional rendering: Only show filter section if there are vehicles */}
            {vehicles.length > 0 && (
                <div className="filter-section">
                    <button 
                        className={`filter-btn ${selectedType === 'All' ? 'active' : ''}`} 
                        onClick={() => setSelectedType('All')}
                    >
                        All
                    </button>
                    <button 
                        className={`filter-btn ${selectedType === 'Mini' ? 'active' : ''}`} 
                        onClick={() => setSelectedType('Mini')}
                    >
                        Mini
                    </button>
                    <button 
                        className={`filter-btn ${selectedType === 'Sedan' ? 'active' : ''}`} 
                        onClick={() => setSelectedType('Sedan')}
                    >
                        Sedan
                    </button>
                    <button 
                        className={`filter-btn ${selectedType === 'SUV' ? 'active' : ''}`} 
                        onClick={() => setSelectedType('SUV')}
                    >
                        SUV
                    </button>
                </div>
            )}

            {/* Conditional rendering for vehicles */}
            {filteredVehicles.length > 0 ? (
                filteredVehicles.map((vehicle, index) => (
                    <div className="vehicle-card" key={index}>
                        {/* Left: Vehicle Image */}
                        <div className="vehicle-left">
                            <img src={vehicle.image || '/assets/img/ertiga_white.png'} className="vehicle-img" alt={vehicle.model} />
                            <p className="vehicle-model" style={{ textAlign: 'center' }}>{vehicle.vehicle_type}</p>
                        </div>

                        {/* Right: Vehicle Details */}
                        <div className="vehicle-right">
                            <div className="vehicle-name-driver-row">
                                <h4 className="vehicle-model">{vehicle.model}</h4> {/* Vehicle model */}

                                {/* Driver Details Button */}
                                <button
                                    className="driver-details-btn"
                                    onClick={() => handleDriverClick(vehicle.driver_name, 'Purulia')} // Replace 'Purulia' with dynamic location
                                >
                                    Details
                                </button>
                            </div>

                            <div className="vehicle-info">
                                <div className="info-row">
                                    <img src="/assets/img/icons/license-plate.svg" alt="License Plate" style={{ width: '20px', marginRight: '5px' }} />
                                    <p>{vehicle.number_plate}</p>
                                    <img src="/assets/img/icons/family.svg" alt="Seats" style={{ width: '20px', marginRight: '5px' }} />
                                    <p>{vehicle.number_of_seats}</p>
                                    <img src="/assets/img/icons/luggage.svg" alt="Luggage" style={{ width: '20px', marginRight: '5px' }} />
                                    <p>{vehicle.luggage_capacity} 4</p>
                                </div>
                            </div>

                            {/* Fare and Book Now Button */}
                            <div className="fare-book-row">
                                <p className="fare-amount"> ₹ {vehicle.calculated_fare}</p>
                                <button className="btn btn-order" onClick={() => handleBookNow(vehicle)}>
                                    Book Now
                                </button>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                vehicles.length > 0 && (
                    <p className="no-vehicles-message">"Sorry,the selected car is currently unavailable. Please choose another option or try again later."</p> // Show this message only if no filtered vehicles are available
                )
            )}

            {/* Popup for Driver Information */}
            {popupVisible && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="close-btn" onClick={closePopup}>&times;</span>
                        <h4>Driver Information</h4>
                        <p><strong><img src="/assets/img/cars/driver.png" alt="car" style={{ width: '20px', marginRight: '5px' }} /></strong> {popupContent.driverName}</p>
                        <p><strong><i className="feather-map-pin me-2"></i></strong> {popupContent.location}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VehicleList;
