import React, { useState } from 'react';
import './OtpLogin.css'; // Import the CSS for styling
import { useDispatch, useSelector } from 'react-redux';
import { sendOtpCode, verifyOtpCode } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

const OtpLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Initialize navigate
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState(['', '', '', '']); // Initialize as array for 4 digits
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    const { status, error } = useSelector((state) => state.auth);

    // Toast for showing error or success messages
    const showToast = (message, type = 'error') => {
        if (type === 'error') {
            toast.error(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            toast.success(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleSendOtp = () => {
        if (!phone || phone.length !== 10 || !/^\d+$/.test(phone)) {
            showToast("Please enter a valid 10-digit mobile number");
            return;
        }
        dispatch(sendOtpCode(phone)).then(() => {
            setIsOtpSent(true);
            showToast("OTP sent successfully", 'success'); // Success toast after OTP sent
        });
    };

    const handleVerifyOtp = () => {
        if (otp.some(digit => digit === '')) {
            showToast("Please enter the complete OTP.");
            return;
        }

        // Attempt to verify the OTP
        dispatch(verifyOtpCode({ phone, otp: otp.join('') }))
            .unwrap()
            .then(() => {
                setIsVerified(true); // Set to true after successful verification
                showToast("OTP verified successfully", 'success'); // Success toast after OTP verification
            })
            .catch(() => {
                showToast("Invalid OTP. Please try again."); // Error toast if OTP is invalid
            });
    };

    const handleOtpChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Automatically focus on the next input if current is filled
            if (index < 3 && value) {
                const nextInput = document.getElementById(`otp-input-${index + 1}`);
                if (nextInput) {
                    nextInput.focus();
                }
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            // If the current input is empty, move to the previous input
            if (otp[index] === '' && index > 0) {
                const prevInput = document.getElementById(`otp-input-${index - 1}`);
                if (prevInput) {
                    prevInput.focus();
                }
            } else {
                // If there's a digit, allow user to erase it in the current input
                const newOtp = [...otp];
                newOtp[index] = ''; // Clear the current input
                setOtp(newOtp);
            }
        }
    };

    const handleResendOtp = () => {
        dispatch(sendOtpCode(phone)).then(() => {
            showToast("New OTP sent successfully", 'success');
        }).catch(() => {
            showToast("Failed to resend OTP. Please try again.");
        });
    };

    // Handle cancel button click - reset state to initial page
    const handleCancel = () => {
        setIsOtpSent(false); // Go back to phone input page
        setOtp(['', '', '', '']); // Clear the OTP input
        setPhone(''); // Clear the phone input
    };

    return (
        <div className="otp-fullscreen">
            {/* Toast container to display notifications */}
            <ToastContainer />

            {!isOtpSent && !isVerified && (
                <div className="otp-screen">
                    <img src="/assets/img/EnterNum.png" alt="Mobile" className="otp-image" />
                    <h2 style={{ fontSize: '18px', fontFamily: 'fangsong' }}>Enter Your Mobile Number</h2>
                    <p>We will send you a confirmation code</p>
                    <div className="input-container">
                        <span className="country-code">
                            <img src="/assets/img/indiaflag.png" alt="India Flag" className="flag-icon" /> +91
                        </span>
                        <input
                            type="tel"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Enter Mobile Number"
                            className="otp-input"
                        />
                    </div>
                    <button
                        onClick={handleSendOtp}
                        className="otp-button"
                        disabled={status === 'loading'} // Disable button when loading
                    >
                        {status === 'loading' ? 'Sending...' : 'Submit'}
                    </button>
                </div>
            )}

            {isOtpSent && !isVerified && (
                <div className="otp-screen">
                    <img src="/assets/img/verify.png" alt="Verification" className="otp-image" />
                    <h2>Enter Verification Code</h2>
                    <p>We are automatically detecting a code sent to {phone}</p>
                    <div className="otp-code-inputs">
                        {[...Array(4)].map((_, index) => (
                            <input
                                key={index}
                                id={`otp-input-${index}`} // Add ID for each input
                                type="number"
                                maxLength="1"
                                value={otp[index]}
                                onChange={(e) => handleOtpChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)} // Add keydown event for backspace handling
                                className="otp-single-input"
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Ensure only numbers are entered
                                }}
                            />
                        ))}
                    </div>
                    <button
                        onClick={handleVerifyOtp}
                        className="otp-button"
                        disabled={status === 'loading'} // Disable button when loading
                    >
                        {status === 'loading' ? 'Verifying...' : 'Verify & Proceed'}
                    </button>
                    <button
                        onClick={handleCancel}
                        className="otp-cancel-button"
                    >
                        Cancel
                    </button>
                    <p className="resend-link">Didn't receive the OTP? <span onClick={handleResendOtp} style={{ cursor: 'pointer', color: 'blue' }}>Resend OTP</span></p>
                </div>
            )}

            {isVerified && (
                <div className="otp-screen">
                    <img src="/assets/img/success.png" alt="Success" className="otp-image-success" />
                    <h2>Success!!!</h2>
                    <p>Logged in. Update your profile.</p>
                    <button
                        onClick={() => navigate('/home')}
                        className="otp-button"
                        disabled={status === 'loading'} // Disable button when loading
                    >
                        {status === 'loading' ? 'Loading...' : 'Okay'}
                    </button>
                </div>
            )}
            {status === 'loading' && <p>Loading...</p>}
            {error && showToast(error)} {/* Show toast if there's an error */}
        </div>
    );
};

export default OtpLogin;
