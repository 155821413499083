import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PoolRide.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChair } from '@fortawesome/free-solid-svg-icons'; // Impor

const PoolRide = () => {
  const [selectedRoute, setSelectedRoute] = useState('All');
  const [selectedDate, setSelectedDate] = useState('Today');
  const [customDate, setCustomDate] = useState('');
  const [selectedSeats, setSelectedSeats] = useState({}); // To track seat status
  const [showPopup, setShowPopup] = useState(false); // To show/hide the popup
  const [rideDetails, setRideDetails] = useState(null); // To store selected ride details
  const [landmark, setLandmark] = useState('');// Add new state to handle landmark input
  const [totalAmount, setTotalAmount] = useState(0); // Track total amount to pay


  const navigate = useNavigate();

  const rides = [
    { id: 1, title: 'Purulia to IXR', from: 'Purulia', to: 'Ranchi', date: '2024-10-28', price: '₹2000', status: 'On way' },
    { id: 2, title: 'Purulia to Durgapur', from: 'Purulia', to: 'Durgapur', date: '2024-10-28', price: '₹1500', status: 'On way' },
    { id: 3, title: 'Purulia to Tata', from: 'Purulia', to: 'Tata', date: '2024-10-28', price: '₹1800', status: 'Available' },
    { id: 4, title: 'Purulia to Asansol', from: 'Purulia', to: 'Asansol', date: '2024-10-28', price: '₹1200', status: 'Available' },
    { id: 5, title: 'Kolkata to Medinipur', from: 'Kolkata', to: 'Medinipur', date: '2024-11-28', price: '₹2500', status: 'Available' },
    { id: 6, title: 'Kolkata to Bankura', from: 'Kolkata', to: 'Bankura', date: '2024-10-28', price: '₹2200', status: 'Available' },
    { id: 7, title: 'Kolkata to Kharagpur', from: 'Kolkata', to: 'Kharagpur', date: '2024-10-30', price: '₹2300', status: 'Available' },
    { id: 8, title: 'Medinipur to Purulia', from: 'Medinipur', to: 'Purulia', date: '2024-10-30', price: '₹1600', status: 'Available' },
  ];

  const routes = ['All', 'Airport', 'Tata', 'Ranchi', 'Asansol', 'Durgapur', 'Kolkata', 'Medinipur', 'Bankura', 'Kharagpur'];
  const dates = ['Today', 'Tomorrow', 'Calendar'];

  const routeDetails = {
    'Airport': ['Purulia to IXR', 'Purulia to Durgapur'],
    'Tata': ['Purulia to Tata'],
    'Ranchi': ['Purulia to Ranchi'],
    'Asansol': ['Purulia to Asansol'],
    'Durgapur': ['Purulia to Durgapur'],
    'Kolkata': ['Kolkata to Medinipur', 'Kolkata to Bankura', 'Kolkata to Kharagpur'],
    'Medinipur': ['Medinipur to Purulia'],
    'Bankura': ['Kolkata to Bankura'],
    'Kharagpur': ['Kolkata to Kharagpur'],
    'All': rides.map(ride => ride.title)
  };

  const getToday = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const getTomorrow = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  };

  const handleRouteClick = (route) => {
    setSelectedRoute(route);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date !== 'Calendar') {
      setCustomDate('');
    }
  };

  const handleCustomDateChange = (e) => {
    setCustomDate(e.target.value);
    setSelectedDate('Calendar');
  };

  const handleBackArrowClick = () => {
    navigate('/');
  };

  const handleSeatClick = (rideId, seatIndex, ride) => {
    setSelectedSeats(prevState => {
      const currentSeats = prevState[rideId] || ['available', 'available', 'available', 'available'];
      const updatedSeats = currentSeats.map((seat, index) => {
        if (index === seatIndex) {
          if (seat === 'available') {
            // Mark seat as selected and calculate total amount
            const seatPrice = parseInt(ride.price.replace('₹', ''));
            setTotalAmount(prevAmount => prevAmount + seatPrice); // Add seat price
            // Show popup when available seat is clicked
            setRideDetails(ride); // Set the selected ride details
            setShowPopup(true); // Show the popup
            return 'selected';
          }
          if (seat === 'selected') {

            // Deselect seat and adjust the total amount
            const seatPrice = parseInt(ride.price.replace('₹', ''));
            setTotalAmount(prevAmount => prevAmount - seatPrice); // Subtract seat price
            return 'available';
          }
        }
        return seat;
      });
      return { ...prevState, [rideId]: updatedSeats };
    });
  };

  const filteredRides = rides.filter(ride => {
    const today = getToday();
    const tomorrow = getTomorrow();
    const rideDate = ride.date;

    const isRouteMatch = routeDetails[selectedRoute]?.includes(ride.title);
    const isDateMatch =
      (selectedDate === 'Today' && rideDate === today) ||
      (selectedDate === 'Tomorrow' && rideDate === tomorrow) ||
      (selectedDate === 'Calendar' && rideDate === customDate);

    return isRouteMatch && isDateMatch;
  });

  const closePopup = () => {
    setShowPopup(false);
  };
  // Function to handle Razorpay payment
  const handlePayment = () => {
    const options = {
      key: "YOUR_RAZORPAY_KEY", // Enter your Razorpay key here
      amount: rideDetails.price.replace('₹', '') * 100, // Convert price to paise (minor unit)
      currency: "INR",
      name: "easyRides",
      description: `Payment for ${rideDetails.title}`,
      handler: function (response) {
        alert("Payment successful! Payment ID: " + response.razorpay_payment_id);
        // Optionally redirect to success page or perform further actions here
      },
      prefill: {
        name: "User Name", // Replace with actual user data if available
        email: "user@example.com", // Replace with actual user data if available
        contact: "1234567890" // Replace with actual user data if available
      },
      theme: {
        color: "#3399cc"
      }
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  return (
    <div className="poolride-container">
      <div className="header-pool">
        <button className="back-arrowPool" onClick={handleBackArrowClick}>&larr;</button>
        <h1>Pool Ride</h1>
      </div>

      <div className="route-filter">
        {routes.map(route => (
          <button
            key={route}
            className={`filter-btn ${route === selectedRoute ? 'active' : ''}`}
            onClick={() => handleRouteClick(route)}
          >
            {route}
          </button>
        ))}
      </div>

      <div className="date-filter">
        {dates.map(date => (
          <button
            key={date}
            className={`filter-btn ${date === selectedDate ? 'active' : ''}`}
            onClick={() => handleDateChange(date)}
          >
            {date}
          </button>
        ))}
      </div>

      {selectedDate === 'Calendar' && (
        <div className="calendar-picker">
          <input
            type="date"
            value={customDate}
            onChange={handleCustomDateChange}
            className="calendar-input"
          />
        </div>
      )}

      <div className="ride-list">
        {filteredRides.length > 0 ? (
          filteredRides.map(ride => (
            <div key={ride.id} className="ride-item">
              <div className="ride-icon">
                <img src="/assets/img/ertiga_white.png" alt="Ride Icon" />
              </div>
              <div className="ride-details">
                <h3>{ride.title}</h3>
                <p className="ride-id">{ride.id}</p>
                <div className="ride-info">
                  <div className="ride-route">
                    <p><strong>From:</strong> {ride.from}</p>
                    <p><strong>To:</strong> {ride.to}</p>
                    <p><strong>Status:</strong> {ride.status}</p>
                    <p><strong>Price:</strong> {ride.price}</p>
                  </div>
                </div>
              </div>

              <div className="seat-layout">
                <div className="driver-seat">
                  <img src="/assets/img/cars/driver.png" alt="Driver Seat" />
                </div>
                <div className="seats">
                  {selectedSeats[ride.id]?.map((seatStatus, index) => (
                    <div
                      key={index}
                      className={`seat ${seatStatus === 'available' ? 'available-seat' : seatStatus === 'selected' ? 'selected-seat' : 'unavailable-seat'}`}
                      onClick={() => handleSeatClick(ride.id, index, ride)}
                    >
                      {/* Add seat icon here */}
                      <img src="/assets/img/car-seat.png" alt="Seat Icon" />
                    </div>
                  )) || (
                      ['available', 'available', 'unavailable', 'available'].map((seatStatus, index) => (
                        <div
                          key={index}
                          className={`seat ${seatStatus === 'available' ? 'available-seat' : seatStatus === 'selected' ? 'selected-seat' : 'unavailable-seat'}`}
                          onClick={() => handleSeatClick(ride.id, index, ride)}
                        >
                          {/* Add seat icon here */}
                          <img src="/assets/img/car-seat.png" alt="Seat Icon" />
                        </div>
                      ))
                    )}
                </div>
              </div>

            </div>
          ))
        ) : (
          <p>No rides available for the selected route and date.</p>
        )}
      </div>

      {/* Popup for pickup address and amount */}
      {showPopup && rideDetails && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={e => e.stopPropagation()}>
            <h3>Pickup Details</h3>
            <p><strong>Pickup Address:</strong> {rideDetails.from}</p>
            <p><strong>Total Amount to Pay :</strong> {totalAmount}</p>
            {/* Landmark input */}
            <div className="landmark-input">
              <label htmlFor="landmark"><strong>Landmark Details:</strong></label>
              <input
                type="text"
                id="landmark"
                value={landmark}
                onChange={(e) => setLandmark(e.target.value)}
                placeholder="Enter any landmark (optional)"
                className="landmark-textbox"
              />
            </div>
            {/* Pay Button */}
            <button className="pay-button" onClick={handlePayment}>Pay Now</button>

            <button className="close-popup" onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PoolRide;
