
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Header from '../components/Main/Header';
import GeocodeComponent from '../components/Main/GeocodeComponent';
import BottomTabBar from '../components/Common/BottomTabBar';


const HomePage = () => {
    const token = useSelector((state) => state.auth.token);

    if (!token) {
        // return <Navigate to="/login" />;
        return <Navigate to="/OtpLogin" />;
    }

    return (
        <div>
            <Header />
            <GeocodeComponent />
            <BottomTabBar />

        </div>
    );
};

export default HomePage;
